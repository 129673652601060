<template>
  <div class="d-flex">
    <v-card
      v-if="isLoading"
      color="primary"
      dark
      width="100%"
      max-width="400"
      class="mx-auto my-6"
    >
      <div class="pt-1 pb-3 px-3 text-body-2">
        Searching duplicates...
        <v-progress-linear indeterminate color="white" class="mb-0" />
      </div>
    </v-card>

    <div v-else-if="isError" class="pa-4 ap-red-10 w-full">
      <ApLogo />

      <div class="d-flex justify-center pa-8">
        <div class="d-flex align-center">
          <div class="mr-2">
            <v-img src="@/assets/images/cyborg.svg" />
          </div>
          <div>
            <h1 class="text-h6 ap-dark-blue--text font-weight-bold">
              CleanData Server Message
            </h1>
            <div class="ap-dark-gray--text">
              CleanData is hard at work analyzing data to identify and fix data
              quality issues.
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-end">
        <v-btn color="ap-red" dark @click="getDupeSet">Try again</v-btn>
      </div>
    </div>

    <DupeGrid
      v-else-if="response"
      title="Duplicate Set"
      :entity-id="entityId"
      :entity-name="entityName"
      :crm-current-url="crmCurrentUrl"
      :user-email-logged-in="userEmailLoggedIn"
      :response="response"
      style="width: 100%"
    />
  </div>
</template>

<script>
import ApLogo from '@/components/common/ApLogo'
import DupeGrid from '@/components/dupegrid/DupeGrid'
import { getDupeSet } from '@/api/dupe-sets/dupe-sets'
import { mapState } from 'vuex'

export default {
  components: {
    DupeGrid,
    ApLogo,
  },
  data() {
    return {
      entityId: null,
      entityName: null,
      response: null,
      isLoading: false,
      isError: false,
    }
  },
  computed: {
    ...mapState('auth', ['agentId', 'contextMessage']),
    userEmailLoggedIn() {
      return this.contextMessage.email
    },
    crmCurrentUrl() {
      return this.contextMessage.url
    },
  },
  created() {
    this.entityId = this.contextMessage.data.entityKey
    this.entityName = this.contextMessage.data.entityName
    this.getDupeSet()
  },
  methods: {
    async getDupeSet() {
      this.isError = false
      this.isLoading = true
      try {
        this.response = await getDupeSet(this.agentId, {
          entityId: this.entityId,
          entityName: this.entityName,
        })
      } catch {
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
